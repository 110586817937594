.fr-logo-container {
    position: relative;
    min-width: 220px;
    min-height: 50px;
    margin: 1em;
}
.fr-logo {
    position: absolute;
    left: 0;
    width: 220px;
    height: 50px;
}
.svg-conifer {
    fill: #026937;
}
.svg-text {
    fill: #026937;
}
.svg-oak {
    fill: #63A70A;
}
.svg-oak-edge {
    fill: #014A2A;
}