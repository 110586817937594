.fc-logo-container {
    position: relative;
    min-width: 268px;
    min-height: 50px;
    margin: 1em;
}
.fc-logo {
    position: absolute;
    left: 0;
    width: 268px;
    height: 50px;
}