.leaflet-container {
    width: 100%;
    height: 100vh;
    z-index: 1;
}
.leaflet-map {
    cursor: pointer;
}
.leaflet-horizontalmiddle {
    /********************************************/
    /* Same as .leaflet-top and .leaflet-bottom */
    position: absolute;
    z-index: 400;
    pointer-events: none;
    /********************************************/
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    padding-top: 10px;
  }
  
  .leaflet-horizontalmiddle .leaflet-control {
    margin-bottom: 10px;
  }
.leaflet-control-zoom-in {
    border-bottom: none !important;
    float: left;
    border-right: 1px solid #ccc;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 4px;
}
.leaflet-control-zoom-out {
    float: right;
    border-left: 1px solid #ccc;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 4px;
}