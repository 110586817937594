.checkbox-param {
    padding: 0 0 1em 1em;
}
.checkbox-container {
    column-count: 3;
    padding: 0;
    margin: 0;
    list-style-type: none;
    max-width: 200px;
}
.checkbox-inline {
    margin: 5px 5px 5px 0;
    display: inline-flex;
    flex-direction: row;
}
.checkbox-label {
    padding-left: 0;
}