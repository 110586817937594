.tooltiptext {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
    max-width: 100%;
    background-color: rgba(85,85,85,0.95);
    color: #fff;
    text-align: center;
    padding: 5px 5px;
    border-radius: 5px;
    z-index: 3;
    bottom: 100%;
    left: 0;
    margin-left: 0px;
    opacity: 0;
    transition: opacity .25s;
}
/* Tooltip arrow */
.tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 10%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}
/* Show the tooltip text when you mouse over the parameter container */
.parameter-container:hover .tooltiptext {
    position: absolute;
    visibility: visible;
    opacity: 1;
    z-index: 3;
}