.legend-container {
    display: inline-flex;
    flex-direction: column;
    align-self: flex-start;
    border: 1px solid rgb(200,200,200);
    border-radius: 5px;
    padding: 0 0.25em;
    min-width: 10em;
}
.legend {
    display: flex;
    flex-direction: row;
}
.legend-colors {
    width: 20px;
    height: 50px;
}
.legend-colors.red {
    background-image: linear-gradient(rgb(255,0,0), rgb(255,255,0));
}
.legend-colors.green {
    background-image: linear-gradient(rgb(0,40,0), rgb(0,255,100));
}
.legend-colors.grey {
    background-image: linear-gradient(rgb(25,25,25), rgb(175,175,175));
}
.legend-text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}
.legend-text {
    margin-left: 0.25em;
}
.legend-text-spacer {
    flex-grow: 1;
}
.legend-toggle {
    text-align: center;
    font-size: 0.8em;
    margin: 0.25em 0;
}