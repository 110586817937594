table {
    border-spacing:0;
    border-collapse: separate;
    margin-bottom: 0.5em;
}
td, th {
    border: 1px solid rgb(100,100,100);
    padding: 0.15em;
    border-top: 0;
}
th {
    text-align: left;
}
td {
    border-left: 0;
}
thead th {
    border: 1px solid rgb(100,100,100);
    background-color: rgb(230,230,230);
}
thead tr:first-child th {
    border-radius: 5px 5px 0 0;
}
tbody tr:last-child th:first-child {
    border-radius: 0 0 0 5px;
}
tbody tr:last-child td:last-child {
    border-radius: 0 0 5px 0;
}