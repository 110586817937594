.panel-content-header {
    position: relative;
    display: inline-flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
    height: 2em;
    background-color: rgb(230,230,230);
    border-top: 1px solid rgb(200,200,200);
    border-bottom: 1px solid rgb(200,200,200);
}