.logo-container {
    display: flex;
    flex-flow: row wrap;
    flex-wrap: wrap;
    margin-top: .5em;
    border-top: 1px solid rgb(230, 230, 230);
    justify-content: center;
}
.developed-by {
    text-align: center;
    font-size: 0.6em;
    margin: 0.25em 0;
}
.developed-by a {
    color: #000;;
    text-decoration: none;
}
.developed-by a:hover {
    text-decoration: underline;
}