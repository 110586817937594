.parameter-container {
    position: relative;
    display: inline-block;
}
.parameter-container.label-above {
    padding-bottom: 1em;
}
.parameter-container.label-left {
    display: flex;
    justify-content: center;
    padding-bottom: 1em;
    border-bottom: 1px solid rgb(230,230,230);
}
label.label-left {
    float: left;
    padding-right: .5em;
}
label.label-above {
    margin: 5px 10px 5px 0;
}
.dropdown {
    max-width: 100%;
    border-radius: 5px;
    -webkit-transition : border .5s ease-out;
    -moz-transition : border .5s ease-out;
    -o-transition : border .5s ease-out;
    transition : border .5s ease-out;
}
.dropdown:hover {
    border-color: #000;
}
.dropdown:focus {
	border-color: #aaa;
	color: #000;
	/*outline: none;*/
}
.dropdown:disabled, .dropdown[aria-disabled=true] {
	color: graytext;
}
.dropdown:disabled:hover, .dropdown[aria-disabled=true] {
	border-color: #aaa;
}