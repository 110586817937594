.cnr-logo {
    max-height: 50px;
    margin: 1em;
    max-width: 75%;
    user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}