.summary-graph {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0.25em 0;
    width: 100%;
}
.loading-histo {
    position: relative;
    width: 100%;
    height: 200px;
    min-width: 190px;
    max-width: 380px;
    height: 200px;
    margin: 0 0 0.25em 0;
}