.submit-button {
    position: relative;
    bottom: 0;
    right: 0;
    outline: none;
    padding: .75em;
    margin: 1em;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-weight: bold;
    color: white;
    background-color: grey;
    transition-duration: 0.25s;
    box-shadow: 1px 2px black;
}
.submit-button:hover {
    background-color: #026937;
    cursor: pointer;
}
.submit-button:active {
    background-color: #014A2A;
    box-shadow: 1px 1px black;
    transform: translateY(2px);
}
.submit-button:disabled,
button[disabled] {
    background-color: rgb(210,210,210);
}