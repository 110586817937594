input, label, select {
    display: block;
}
.form-inline {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.form-inline input {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 10px;
}